<template>
  <div>
    <b-row class="my-4">
      <b-col cols="12">
        <h3 class="center text-info float-left text-uppercase">
          {{ $t('sections.download') }}
        </h3>

        <router-link :to="{ name: 'general-downloads-create' }">
          <b-button size="sm" class="ml-3" variant="success">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-plus-circle-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5
                4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5
                0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"
              />
            </svg>
            {{ $t('buttons.create') }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>

    <b-table
      show-empty
      responsive
      bordered
      striped
      hover
      :busy="loading"
      :items="list"
      :fields="fields"
      :emptyText="$t('messages.emptyTable')"
    >
      <template v-slot:table-busy>
        <div
          class="
            text-center text-danger
            my-2
            align-items-center
            d-flex
            flex-column
          "
        >
          <b-spinner
            class="align-middle flex-direction-column mb-3"
          ></b-spinner>
          <strong>{{ $t('messages.loading') }}...</strong>
        </div>
      </template>

      <template v-slot:cell(nameEN)="data">
        <p class="text-left m-0" style="max-width: 500px">{{ data.value }}</p>
      </template>

      <template v-slot:cell(namePT)="data">
        <p class="text-left m-0" style="max-width: 500px">{{ data.value }}</p>
      </template>

      <template v-slot:cell(type)="data">
        <p class="text-left m-0" style="max-width: 500px">
          {{ $i18n.locale === 'en' ? data.value.nameEN : data.value.namePT }}
        </p>
      </template>

      <template v-slot:cell(category)="data">
        <p class="text-left m-0" style="max-width: 500px">
          {{ $i18n.locale === 'en' ? data.value.nameEN : data.value.namePT }}
        </p>
      </template>

      <template v-slot:cell(preview)="data">
        <b-img
          :src="data.value"
          height="50px"
          width="100px"
          thumbnail
        />
      </template>

      <template v-slot:cell(previewFilename)="data">
        <b-img
          :src="data.value"
          height="50px"
          width="100px"
          thumbnail
          v-if="data.value.indexOf('png') !== -1
          || data.value.indexOf('jpg') !== -1
          || data.value.indexOf('jpeg') !== -1"
        />
        <a download :href="data.value" v-else>
          {{ data.item.filename }}
        </a>
      </template>

      <template v-slot:cell(actions)="data">
        <p class="text-center m-0">
          <b-button
            size="sm"
            variant="info"
            @click="
              $router.push({
                name: 'general-downloads-edit',
                params: { id: data.item.id }
              })
            "
          >
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              class="bi bi-pencil-square"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5
                0 0 1 .707 0l1.293 1.293zm-1.75 2.456l-2-2L4.939 9.21a.5.5 0 0
                0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0
                0 .196-.12l6.813-6.814z"
              />
              <path
                fill-rule="evenodd"
                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0
                0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0
                1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
              />
            </svg>
            {{ $t('buttons.edit') }}
          </b-button>

          <span class="mx-1" />

          <b-button
            size="sm"
            variant="danger"
            @click="
              deleteModal = true;
              deleteId = data.item.id;
            "
          >
            <b-icon icon="trash" />
            {{ $t('buttons.delete') }}
          </b-button>
        </p>
      </template>
    </b-table>
    <b-modal
      centered
      v-model="deleteModal"
      @ok="onDelete"
      :ok-title="$t('buttons.delete').toUpperCase()"
      :cancel-title="$t('buttons.cancel').toUpperCase()"
      ok-variant="danger"
    >
      <div class="d-block">
        {{ $t('messages.deleteConfirmMessage') }}
        <strong>{{ $t('messages.deleteConfirmMessageAlert') }}</strong>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import GeneralDownload from '../../../../services/GeneralDownload';
import Toast from '../../../../assets/js/toast';

export default {
  data() {
    const data = {
      list: [],
      loading: true,
      deleteModal: false,
      deleteId: null,
    };

    return data;
  },
  beforeMount() {
    this.onLoad();
  },
  methods: {
    async onLoad() {
      try {
        const lst = await GeneralDownload.getDownloads();
        this.list = lst.map((obj) => ({
          ...obj,
          preview: `${Vue.prototype.VUE_APP_S3_URL}/downloads/${obj.thumbnail}`,
          previewFilename: `${Vue.prototype.VUE_APP_S3_URL}/downloads/${obj.filename}`,
        }));
      } catch (error) {
        Sentry.captureException(error);
      } finally {
        this.loading = false;
      }
    },
    async onDelete() {
      try {
        this.loading = true;

        await GeneralDownload.delete(this.deleteId);
        Toast.success(this, 'messages.removeSuccess');
      } catch (e) {
        Sentry.withScope((scope) => {
          scope.setExtra('deleteId', this.deleteId);
          Sentry.captureException(e);
        });

        Toast.error(this, e);
      } finally {
        this.deleteId = null;
        this.onLoad();
      }
    },
  },
  computed: {
    fields() {
      return [
        {
          key: 'preview',
          label: 'Thumb',
        },
        {
          key: 'previewFilename',
          label: 'Arquivo',
        },
        {
          key: this.$i18n.locale === 'en' ? 'nameEN' : 'namePT',
          label: this.$t('inputs.name'),
          sortable: true,
        },
        { key: 'type', label: this.$t('inputs.type'), sortable: false },
        {
          key: 'category',
          label: this.$t('inputs.categories'),
          sortable: false,
        },
        { key: 'actions', label: this.$t('inputs.action') },
      ];
    },
  },
};
</script>

<style>
</style>
